import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { useAuth } from "@/stores/auth";
import { useAction } from "@/stores/action";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:slug",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "school",
        name: "school",
        component: () => import("@/views/pages/school/school.vue"),
        children: [
          {
            path: "edit/:id",
            name: "school-edit",
            component: () => import("@/views/pages/school/schoolEdit.vue"),
          },
          {
            path: "payment/:id",
            name: "school-payment",
            component: () => import("@/views/pages/school/schoolPayment.vue"),
          },
        ],
      },
      {
        path: "course",
        name: "course",
        component: () => import("@/views/pages/course/Course.vue"),
        children: [
          {
            path: "index",
            name: "course-index",
            component: () => import("@/views/pages/course/CourseIndex.vue"),
          },
          {
            path: "create",
            name: "course-create",
            component: () => import("@/views/pages/course/CourseCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "course-edit",
            component: () => import("@/views/pages/course/CourseCreate.vue"),
          },
          {
            path: "preview/admin/:id",
            name: "course-preview-admin",
            component: () =>
              import("@/views/pages/course/CoursePreviewAdmin.vue"),
          },
          {
            path: "preview/:id",
            name: "course-preview",
            component: () => import("@/views/pages/course/CoursePreview.vue"),
          },
          {
            path: "preview/:id/:moduleId",
            name: "module-preview",
            component: () => import("@/views/pages/module/ModulePreview.vue"),
            children: [
              {
                path: ":moduleItemId",
                name: "module-item-preview",
                component: () =>
                  import("@/views/pages/module/ModuleItemPreview.vue"),
                props: true,
              },
            ],
          },
          {
            path: "admin/:id/:moduleId",
            name: "module-preview-admin",
            component: () => import("@/views/pages/module/ModulePreview.vue"),
            children: [
              {
                path: ":moduleItemId",
                name: "module-item-preview-admin",
                component: () =>
                  import("@/views/pages/module/ModuleItemPreview.vue"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/views/pages/user/User.vue"),
        children: [
          {
            path: "index",
            name: "user-index",
            component: () => import("@/views/pages/user/UserIndex.vue"),
          },
          {
            path: "create",
            name: "user-create",
            component: () => import("@/views/pages/user/UserCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "user-edit",
            component: () => import("@/views/pages/user/UserEdit.vue"),
          },
          {
            path: "password/:id",
            name: "user-password",
            component: () => import("@/views/pages/user/UserPassword.vue"),
          },
        ],
      },
      {
        path: "calendar",
        name: "calendar",
        component: () => import("@/views/pages/calendar/Calendar.vue"),
        children: [
          {
            path: "index",
            name: "calendar-index",
            component: () => import("@/views/pages/calendar/CalendarIndex.vue"),
          },
        ],
      },
      {
        path: "test",
        name: "test",
        component: () => import("@/views/pages/test/Test.vue"),
        children: [
          {
            path: "index",
            name: "test-index",
            component: () => import("@/views/pages/test/TestIndex.vue"),
          },
          {
            path: "create",
            name: "test-create",
            component: () => import("@/views/pages/test/TestCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "test-edit",
            component: () => import("@/views/pages/test/TestCreate.vue"),
          },
          {
            path: "preview/:id",
            name: "test-preview",
            component: () => import("@/views/pages/test/TestPreview.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/:slug/sign-in",
        name: "slug-sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const auth = useAuth();
  await auth
    .verifyAuth(to)
    .then((response) => {
      if (response == "dashboard") {
        next({ name: "dashboard", params: { slug: to.params.slug } });
      }
      next();
    })
    .catch(() => {
      next({ name: "slug-sign-in", params: { slug: to.params.slug } });
    });

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 50);
});

router.afterEach(() => {
  useAction().setClearAction();
});

export default router;
