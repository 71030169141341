import { defineStore } from "pinia";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import jwt_decode from "jwt-decode";
import { RoleEnums } from "@/enums/roleEnums";

export interface School {
  id: number;
  name: string;
  slug: string;
}

export interface Payment {
  price: number;
  paid: boolean;
}

export interface User {
  id: number;
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
  role: any[];
  image: string;
  school: any;
  logoBlackBcg: string;
  paymentAdmin: any;
}

export const useAuth = defineStore("auth", {
  state: () => ({
    errors: [],
    user: {} as User,
    isAuthenticated: !!JwtService.getToken(),
    isAdmin: false,
    isStudent: false,
  }),
  getters: {
    currentPaymentAdmin(): Payment {
      return this.user.paymentAdmin;
    },
    currentSchool(): School {
      return this.user.school;
    },
    currentUser(): User {
      return this.user;
    },
    currentUserIdIri(): string {
      return '/api/users/'+this.user.id;
    },
    currentUserImage(): string {
      return this.user.image != ""
        ? process.env.VUE_APP_BACKEND_URL + "" + this.user.image
        : "";
    },
    currentUserId(): number {
      return this.user.id;
    },
    currentUserRoleIsAdmin(): boolean {
      return this.isAdmin;
    },
    currentUserRoleIsStudent(): boolean {
      return this.isStudent;
    },
    isUserAuthenticated(): boolean {
      return this.isAuthenticated;
    },
    currentUserLogoBlackBcg(): string {
      return this.user.logoBlackBcg
        ? process.env.VUE_APP_BACKEND_URL + "" + this.user.logoBlackBcg
        : "";
    },
    getErrors(): Array<string> {
      return this.errors;
    },
  },
  actions: {
    logout() {
      this.isAuthenticated = false;
      this.user = {} as User;
      this.errors = [];
      JwtService.destroyToken();
    },
    loginData(data) {
      this.user = jwt_decode(data.token);
      this.isAuthenticated = true;
      this.errors = [];
      this.isAdmin = this.user.role.includes(RoleEnums.ROLE_ADMIN);
      this.isStudent = this.user.role.includes(RoleEnums.ROLE_STUDENT);
      JwtService.saveToken(data.token);
    },
    login(credentials) {
      return new Promise<void>((resolve, reject) => {
        ApiService.post("user/login", credentials)
          .then(({ data }) => {
            this.loginData(data);
            resolve();
          })
          .catch(({ response }) => {
            this.errors = response.data.errors;
            reject();
          });
      });
    },
    register(credentials) {
      return new Promise<void>((resolve, reject) => {
        ApiService.post("user/register", credentials)
          .then(({ data }) => {
            this.loginData(data);
            resolve();
          })
          .catch(({ response }) => {
            this.errors = response.data.errors;
            reject();
          });
      });
    },
    verifyAuth(to) {
      const publicPages = [
        "sign-in",
        "sign-up",
        "slug-sign-in",
        "password-reset",
        "404",
        "500",
      ];
      const authNotRequired = publicPages.includes(to.name);
      ApiService.setHeader();
      return new Promise<void | string>((resolve, reject) => {
        if (authNotRequired) {
          if (this.isAuthenticated && to.name == "slug-sign-in") {
            resolve("dashboard");
          }
          resolve();
        }
        if (!JwtService.getToken()) {
          reject();
        } else {
          ApiService.post("user/verify_auth", { token: JwtService.getToken() })
            .then(({ data }) => {
              this.loginData(data);
              resolve();
            })
            .catch(({ response }) => {
              this.logout();
              reject();
            });
        }
      });
    },
    updateUserImage(image) {
      this.user.image = image;
    },
  },
});
