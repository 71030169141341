import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import pl from 'element-plus/dist/locale/pl.js'
import i18n from "@/core/plugins/i18n";
import { createPinia } from 'pinia';

//imports for app initialization
import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

/* font awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: pl,
});
app.use(VuePlyr, {
  plyr: {},
});

ApiService.init(app);
// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("QuillEditor", QuillEditor);
app.use(i18n);
app.mount("#app");
