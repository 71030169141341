import { defineStore } from 'pinia';

interface Action {
    title: string;
    route: {};
}

export const useAction = defineStore('action', {
    state: () => ({
        action: {} as Action
    }),
    getters: {
        getAction(): Action {
            return this.action;
        }
    },
    actions: {
        setAction(title, route) {
            this.action = {title: title, route: route};
        },
        setClearAction() {
            this.action = {title: '', route: ''}
        }
    }
});