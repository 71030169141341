import { createI18n } from "vue-i18n";

const messages = {
  en: {
    dashboard: "Dashboard",
    fieldRequired: 'Field is required',
    logout: 'Logout',
    schoolSettings: 'School settings',
    payment: 'Payment'
  },
  pl: {
    dashboard: "Dashboard",
    fieldRequired: 'To pole jest wymagane',
    logout: 'Wyloguj',
    schoolSettings: 'Ustawienia szkoły',
    payment: 'Płatność'
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'pl',
  fallbackLocale: 'pl',
  globalInjection: true,
  messages
});

export default i18n;
